<template>
  <section class="userSelected">
    <div class="userSelected__containerRow">
      <div class="userSelected__userInfo">
        <div class="userSelected__avatarContainer" :stateAvatar="state">
          <Avatar class="userSelected__avatar" :user="user" />
          <span class="userSelected__statusCircle" :stateAvatar="state"></span>
        </div>
        <div class="userSelected__userInfoName">
          <div class="userSelected__contentRow">
            <p class="userSelected__userName">{{ user.user }}</p>
            <iconic :class="`userSelected__flag userSelected__flag--${user.redFlag && 'active'}`" name="flag" v-if="user.role === 'model'" @click.native="setFlag" />
          </div>
          <p>{{ user.city }}</p>
          <div class="userSelected-starts" v-show="user.qualification && $userData.role === 'superadmin'">
            <span class="userSelected-start m" v-for="star in valuesStars" :value="star" :key="star">★</span>
          </div>
        </div>
      </div>
      <div class="userSelected__containerButtons" v-if="screenWidth >= 1024">
        <button :class="`userSelected__button userSelected__button--${option.style}`" v-for="(option, idx) in nonBackupButtons()" :key="idx + option.name" @click="executeOption(option)">
          {{ option.name }}
        </button>
        <DropDownCollapse title="Reconexión de Respaldo" :items="backupButtons()" v-if="showDropdown" />
      </div>
      <button class="userSelected__buttonAct tool-tip" v-else @click="menuAssign(propsMenu, $event)">
        <iconic class="userSelected__icon" name="ellipsis_v" />
      </button>
    </div>
    <div class="userSelected__containerRouterLinks">
      <router-link class="userSelected__routerLink" v-for="(route, idx) in routerLinks" :key="route.to + idx" :to="{ name: route.to, query: $route.query }">
        {{ route.name }}
      </router-link>
    </div>
    <router-view />
  </section>
</template>

<script>
  import AvatarProfile from "@/components/main-avatar/AvatarProfile.vue";
  import DropDownCollapse from "@/components/DropDownCollapse/DropdownCollapse.vue";
  import { autoLogin } from "@/js/applications";
  import { socket } from "@/socket";
  import { mapActions, mapGetters } from "vuex";
  export default {
    components: { AvatarProfile, DropDownCollapse },
    data() {
      return {
        userId: "",
        userFind: {},
        valuesStars: ["1", "2", "3", "4 ", "5"],
        showDropdown: false,
      };
    },
    computed: {
      ...mapGetters("user", { getProfile: "getProfile" }),
      ...mapGetters("connectedUsers", { getUserById: "getUserById" }),
      userCanUseBot() {
        const { city, role } = this.getProfile;

        return process.env.VUE_APP_MODE !== "prod" || role === "monitor" || city === "Medellín";
      },
      screenWidth() {
        return this.$screen.width;
      },
      user() {
        const userInData = this.userFind;
        const userIsConected = this.getUserById(this.userId);

        userInData.isOnline = userIsConected ? userIsConected.isOnline : userInData.isOnline;
        userInData.isActive = userIsConected ? userIsConected.isActive : userInData.isActive;
        return userInData;
      },
      userIsConected() {
        return this.$store.getters["connectedUsers/getUserById"](this.userId);
      },
      state() {
        const { isActive, isOnline } = this.user;
        return isActive && isOnline ? "online" : !isActive && isOnline ? "away" : "offline";
      },
      propsMenu() {
        const { artisticName = "", email = "", streamateUser, streamatePassword, jasminUser, jasminPassword } = this.user;

        const propsMenu = {
          title: "",
          options: [],
        };

        const buildOpt = (name, icon, func, param, style, link) => {
          return { name, icon, func, param, style, link };
        };
        const addOption = (opt, method = "push") => {
          propsMenu.options?.[method]?.(opt);
        };

        if (streamateUser) {
          if (this.userCanUseBot) {
            addOption(buildOpt("Streamate", "user", "authStreamate", [{ streamateUser, streamatePassword }], "streamate", "https://www.streamatemodels.com/img/logo192.png"));
          }

          addOption(
            buildOpt(this.userCanUseBot ? "Respaldo" : "Streamate Model", "user", "openAutoLogin", [`/downloadfilevbsstreamate?model=${email}`, "streamate"], "streamate", "src/assets/img/str.png")
          );
        }
        if (jasminUser) {
          if (this.userCanUseBot) {
            addOption(buildOpt("Live Jasmin", "user", "authModelcenter", [{ jasminUser, jasminPassword }], "jasmin", "https://staticx1.dditscdn.com/msc/jasmin/5afe5df7283c72ee0d9d.svg"), "unshift");
          }

          addOption(
            buildOpt(
              this.userCanUseBot ? "Respaldo" : "Live Jasmin",
              "user",
              "openAutoLogin",
              [`/downloadfilevbsweb?model=${email}`, "jasminweb"],
              "jasmin",
              "https://staticx1.dditscdn.com/msc/jasmin/5afe5df7283c72ee0d9d.svg"
            ),
            "unshift"
          );
          addOption(buildOpt("Jasminer", "transmission", "openLink", [`http://www.livejasminer.com/${artisticName.replace(/\s+/g, "")}-activity-statistics.html`], "jasminer"));
        }

        return propsMenu;
      },
      routerLinks() {
        const { user = {} } = this;
        const menus = [
          { name: "General", to: "generalInfo", props: { user } },
          { name: "Aplicaciones", to: "applications", props: { user } },
        ];

        if (this.$userData.role === "monitor") {
          const idx = menus.indexOf((menu) => menu.name === "Aplicaciones");
          menus.splice(idx, 1, 0);
        }

        return menus;
      },
    },
    watch: {
      "$route.params.id"(val) {
        if (!val) {
          this.$router.replace("/main");
        }
        this.userId = val;
      },
      async userId() {
        this.userFind = await this.getUserInfo();
        this.$store.state.user.userSelected = this.userFind;
        this.setQualification();
      },
    },
    methods: {
      ...mapActions("platforms", ["authStreamate", "authModelcenter"]),

      async getUserInfo() {
        const { data } = await this.$axios.get(`/user/${this.userId}`);
        const { user } = data;

        return user;
      },
      setQualification() {
        const starts = document.querySelectorAll(`.m`);
        starts.forEach((start) => {
          start.classList.remove("starActive");

          if (this.user.qualification !== "NaN" && start.getAttribute("value") <= this.user.qualification) {
            start.classList.add("starActive");
          }
        });
      },
      openAutoLogin: function(endpoint, platform, body) {
        autoLogin(endpoint, platform, body, this.$store);
      },
      openLink(url) {
        window.open(url, "_private").focus();
      },
      executeOption(option) {
        const { func, param } = option;
        if (!func) return;

        this.showDropdown = this.userCanUseBot;
        this[func](...param);
        this.$store.state.user.menuAssign = {};
      },
      menuAssign: function(propsMenu, evt) {
        propsMenu.event = evt;
        this.$store.state.user.menuAssign = propsMenu;
      },
      setFlag() {
        const { _id, redFlag = false } = this.user;
        const body = { id: _id, redFlag: !redFlag };

        socket.emit("updateUser", body);
      },
      nonBackupButtons() {
        return this.propsMenu.options.filter((button) => button.name !== "Respaldo");
      },
      backupButtons() {
        return this.propsMenu.options.reduce((acc, option) => {
          if (option.name === "Respaldo") {
            acc.push({
              ...option,
              name: option.style === "jasmin" ? "Live Jasmin" : "Streamate",
              action: (opt) => this.executeOption(opt),
              requireOpts: true,
            });
          }
          return acc;
        }, []);
      },
    },
    mounted() {
      const userId = this.$route.params.id;
      this.userId = userId;
    },
  };
</script>

<style lang="scss">
  .userSelected {
    @include Flex(column);
    width: 100%;
    padding: $mpadding 10px;
    gap: 20px;
    max-width: 1000px;
    &__containerRow {
      @include Flex(row, space-between, flex-start);
      width: 100%;
    }
    &__userInfo {
      @include Flex(row, space-between, flex-start);
      height: 100%;
      gap: $mpadding;
      padding-top: $space-24;
    }
    &__containerButtons {
      padding-top: $space-20;
      padding-left: $space-20;
      @include Flex(row, flex-end);
      gap: 12px;
      flex-wrap: wrap;
    }
    &__containerRouterLinks {
      @include Flex(row, flex-start);
      width: 100%;
    }
    &__routerLink {
      @include Flex(row);
      height: 33px;
      padding: 5px 10px;
      text-decoration: none;
      color: black;
      &:hover {
        color: $primary-color;
      }
    }
    a {
      text-decoration: none;
    }
    &__contentRow {
      @include Flex(row);
      gap: 8px;
    }
    &__flag {
      padding: 2px;
      font-size: 1.1em;
      color: #d9d9d9;
      cursor: pointer;
      transition: 0.2s ease-in-out;
      &:hover {
        color: $primary-color;
      }
      &--active {
        color: $primary-color;
      }
      &--active:hover {
        color: #d9d9d9;
      }
    }
    &__userName {
      font-size: 24px;
      font-family: $sec_font;
    }
    &-starts {
      font-size: 25px;
      color: #ccc;
      margin-top: -10px;
    }
    .starActive {
      color: $primary-color;
    }
    &__button {
      white-space: nowrap;
      padding: 4px 10px;
      user-select: none;
      color: $white;
      background-color: $primary-color;
      border: none;
      border-radius: 5px;
      &:hover {
        opacity: $opacity-button;
      }
      &:active {
        transform: scale(0.97);
      }
      &--streamate {
        background-color: $matisse;
      }
      &--jasminer {
        background-color: #ececec;
        color: $lipstick;
        font-weight: 700;
      }
    }
    &__buttonAct {
      @include Flex(row);
      width: 33px;
      height: 33px;
      border: none;
      background-color: transparent;
      border-radius: 50%;
      transition: 0.3s ease-in-out;
      &:hover {
        background-color: $alto;
      }
    }
    &__icon {
      font-size: 20px;
    }
    &__avatarContainer {
      position: relative;
      width: fit-content;
      border: 3px solid $alto;
      border-radius: 50%;
      &[stateAvatar="online"] {
        border-color: $la_palma;
      }
      &[stateAvatar="away"] {
        border-color: $tree_Poppy;
      }
    }
    &__avatar {
      width: 77px;
      height: 77px;
      font-size: 2em;
      border: 2px solid $white;
    }
    &__statusCircle {
      position: absolute;
      width: 17px;
      height: 17px;
      right: 2px;
      bottom: 2px;
      border: 2px solid $white;
      border-radius: 50%;
      background-color: $alto;
      &[stateAvatar="online"] {
        background-color: $la_palma;
      }
      &[stateAvatar="away"] {
        background-color: $tree_Poppy;
      }
    }
    .router-link-exact-active {
      color: $primary-color;
      font-weight: 600;
      border-bottom: 3px solid $primary-color;
      &:hover {
        color: $primary-color;
      }
    }
    @media (min-width: 700px) {
      padding: 0 45px;
    }
  }
</style>
